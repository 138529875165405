import React from 'react'
import Main from '../AboutComponent/Main'

const About = () => {
  return (
    <>
      <Main/>
    </>
  )
}

export default About
