import React from 'react'
import { Link } from 'react-router-dom'

const OderSucessPage = () => {


    return (
        <>
            <div className="success-page">
                <div className="success-page-wrap">
                    <div className='ty'>Thankyou</div>
                    <div className='po'>Your oder has been placed</div>
                    <div className='li'>
                        <Link to={'/'}>
                            Continue Shopping
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OderSucessPage