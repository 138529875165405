import React from 'react'
import Main from '../JoinUSComponent/Main'

const JoinUs = () => {
  return (
    <>
      <Main />
    </>
  )
}

export default JoinUs
