import React from 'react'
import Main from '../JoinAsACustomerComponent/Main'

const JoinAsAMember = () => {
  return (
    <>
      <Main />
    </>
  )
}

export default JoinAsAMember
