import React from 'react'
import Main from '../AddToCartComponent/Main'

const AddToCart = ({getCartCount}) => {
  return (
    <>
      <Main getCartCount={getCartCount}/>
    </>
  )
}

export default AddToCart
