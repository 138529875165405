import React, { useEffect, useState } from "react";
import { Button, Container, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import Slider from "react-slick";

const Hero = () => {

  var settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
  };

  const [showPopup, setShowPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleShow = (message) => {
    setErrorMessage(message);
    setShowPopup(true);
  };

  const handleClose = () => setShowPopup(false);
  const [data, setData] = useState([]);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem(
          "ihQFBEA0aED0fTxNKSd4gPi9jDXgIVh4YR13CoFE"
        );

        const response = await axios.get(
          "https://max88backend.testingwebsitelink.com/api/banner",
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setData(response.data.banner);
      } catch (error) {
        handleShow(error?.response?.data?.message);
      }
    };
    const fetchThreeProducts = async () => {
      try {
        const token = localStorage.getItem(
          "ihQFBEA0aED0fTxNKSd4gPi9jDXgIVh4YR13CoFE"
        );

        const response = await axios.get(
          "https://max88backend.testingwebsitelink.com/api/three_products_get",
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setProducts(response.data.products);
      } catch (error) {
        handleShow(error?.response?.data?.message);
      }
    };

    fetchThreeProducts();
    fetchData();
  }, []);

  const processDescription = (description) => {
    return description
      .replace(/<\/?b>/g, '')
      .split('|')
      .map((part, index) => (
        <React.Fragment key={index}>
          <strong>{part.trim()}</strong>
          {index < description.split('|').length - 1 && <br />} 
        </React.Fragment>
      ));
  };
  return (
    <>
      <Container className="custom-container">
        <div>
          <div className="row imgSec">
            <div className="col-lg-6" data-aos="fade-right">
              <Slider {...settings}>
                {data.map((banner, index) => (
                  <div key={index}>
                    <img
                      style={{ objectFit: "contain" }}
                      src={banner.image}
                      alt="Slide 1"
                    />
                  </div>
                ))}
              </Slider>
            </div>
            <div className="col-lg-6 heroImageDiv2" data-aos="fade-left">
              <div className="row heroImageDiv2Row">
                <div className="row  heroImage1">
                  <Link className="row heroImg1-a heroImageHeig">
                    <div className="heroImage2IMG1 col-lg-6">
                      <div>
                        <h3>VERT</h3>
                      </div>
                      <div>
                        <p>
                          Packed with 8 green extracts to cleanse, lighten,
                          soothe, and hydrate your skin in just 2 weeks!
                        </p>
                      </div>
                    </div>
                    <div className="banner2-right col-lg-6">
                      <img
                        width={60}
                        src={require("../assets/images/banne2vert1.png")}
                        alt="Google"
                      />
                    </div>
                  </Link>
                </div>

                <div className="row heroImage2 heroImageHeig">
                  <Link className="row heroImg2-a">
                    <div className="heroImage2IMG2 col-lg-6">
                      <div>
                        <h3 className="text-white">ROGUE</h3>
                      </div>
                      <div>
                        <p className="text-white">
                          Achieve radiant skin in 2 weeks with our all-natural
                          red fruit & flower soap enriched with 8 powerful
                          extracts!
                        </p>
                      </div>
                    </div>
                    <div className="banner2-right col-lg-6">
                      <img
                        width={60}
                        src={require("../assets/images/banne2rouge1.png")}
                        alt="Google"
                      />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div data-aos="flip-up">
              <div className="bannerBody2">
                <div>
                  <img
                    width={60}
                    src={require("../assets/images/bannerFruitsLeft.png")}
                    alt="Google"
                  />
                </div>
                <h5 className="bannerText">
                  Wellness that works. From the inside out. #BESTLIFEEVER
                </h5>
                <div>
                  <img
                    width={60}
                    src={require("../assets/images/bannerFruitsRight.png")}
                    alt="Google"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 productDiv">
          <div className="col-lg-10 spection1" data-aos="zoom-in-up">
            <div className="productDivSec">
              <h2>Empower Your Body's Long-Term Health</h2>
            </div>
            <div className="col-lg-8 productDivSec" data-aos="zoom-in-up">
              <h5>
                Wellness Made Easy: Products for a Healthier, More Confident
                You.
              </h5>
            </div>
          </div>
          <div className="col-12">
            <div>
              <ul className="row listCardUl">
                {products.map((product, index) => (
                  <li className="col-lg-3 col-md-6 col-12" key={index} data-aos="zoom-in">
                    <div className="listCardUiDiv">
                      <Link to={`/productdetail/${product.id}`}>
                        <div className="listCardImgCatgegory">
                          <img
                            className=""
                            width={300}
                            src={product?.main_image}
                            alt="special-online-shop"
                          />
                        </div>
                        <div className="subtitleBox">
                          <h4>{product.tittle}</h4>
                          <p className="product-card-desc">
                            {product.description
                              ? (product.description.length > 30
                                ? processDescription(`${product.description.substring(0, 49)} `)
                                : processDescription(product.description))
                              : 'No description available'}<a className="textarrr" href="#">Read More..</a>
                          </p>
                        </div>
                      </Link>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </Container>
      <Modal show={showPopup} onHide={handleClose} className="error-modal">
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorMessage}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            className="error-button-close"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Hero;
