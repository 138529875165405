import React from 'react'
import {BannerMain} from "../BannerComponent/BannerMain"

const Banner = () => {
  return (
    <>
     <BannerMain />
    </>
  )
}

export default Banner
