import React, { useEffect, useState } from "react";
import { Container, Button, Spinner } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Main = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const ref = params.get('ref');
  const [isLoading, setIsLoading] = useState(true);
  const userId = sessionStorage.getItem('userId')
  const navigate = useNavigate()
  useEffect(() => {
    if (userId) {
      navigate('/')
    } else {
      setIsLoading(false)
    }
  }, []);
  return (
    <>

      {isLoading ?
        <div className="loader-container text-center loader-container-shop">
          <Spinner animation="border" role="status"></Spinner>
        </div>
        :
        <Container>
          <div className="row joinusMainBanner">
            <div className="col-lg-11 joinusMainBgImg">
            </div>
          </div>
          <div className="row joinSec2">
            <div className="col-lg-8">
              <div className="Joinsec2Texth3">
                <h3>Join the Maximum88 Family</h3>
              </div>
              <div>
                <p>
                  “Unleash your full potential and embark on an unforgettable
                  journey to greatness. It starts with a decision!“
                </p>
              </div>
            </div>
          </div>
          <div className="row joinSec3">
            <div className="col-lg-6 joinSec3Img">
              <img src={require("../assets/images/joinSec2.png")} alt="Google" />
            </div>
            <div className="row col-lg-6 joinSec3Col2">
              <div>
                <h3>Preferred Customer</h3>
              </div>
              <div>
                <p>
                  <b>Unlock exclusive benefits!</b> Shop with your Preferred
                  Customer account and enjoy 10% off, plus a handy dashboard to
                  track orders, get discounts, and access special offers.
                </p>
              </div>
              <div className="joinSec3Button">
                {ref != '' && ref != null && ref != undefined ?
                  <Link to={`/register?ref=${ref}`}>
                    <Button>JOIN US PREFERRED CUSTOMER</Button>
                  </Link>
                  :
                  <Link to="/register">
                    <Button>JOIN US PREFERRED CUSTOMER</Button>
                  </Link>
                }
              </div>
            </div>
          </div>
          <div className="row joinSec4">
            <div className="row col-lg-6 joinSec4Text">
              <div>
                <h3>Be an Independent Distributor</h3>
              </div>
              <div>
                <p>
                  Why become one? You become your boss as you enter this program!
                  Enjoy full discounts and earn full retail income when you share
                  our products. Get calculated bonuses as your business grows &
                  hit incentive rewards as you reach key milestones in your
                  business!
                </p>
              </div>
              <div className="joinSec4Button">
                {ref != '' && ref != null && ref != undefined ?
                  <Link to={`/selectmaxpack?ref=${ref}`}>
                    <Button>JOIN AS INDEPENDENT DISTRIBUTOR</Button>
                  </Link>
                  :
                  <Link to={`/selectmaxpack`}>
                    <Button>JOIN AS INDEPENDENT DISTRIBUTOR</Button>
                  </Link>
                }
              </div>
            </div>
            <div className="col-lg-6">
              <img
                className="img-w"
                src={require("../assets/images/max88_ind_des.png")}
                alt="Google"
                width={'100%'}
              />
            </div>
          </div>
        </Container>
      }
    </>
  );
};

export default Main;
