import React from 'react'
import Main from '../OtpComponent/Main'

const Otp = () => {
  return (
    <>
      <Main />
    </>
  )
}

export default Otp
