import React from 'react'
import Main from '../shopComponent/Main'

const Shop = ({getCartCount}) => {
  return (
    <>
      <Main getCartCount={getCartCount}/>
    </>
  )
}

export default Shop
