import React from 'react'
import Main from '../DashboardComponent/Main'

const Dashboard = () => {
  return (
    <>
      <Main />
    </>
  )
}

export default Dashboard
