import React from "react";
import { Container, Button, Carousel, Card, Row, Col } from "react-bootstrap";
import { PiInstagramLogo } from "react-icons/pi";
import { Link } from "react-router-dom";

const Main = () => {
  const leaders = [
    {
      id: 1,
      img: "'../assets/images/leader1.png'",
      name: "Mark Anthony Dapiton",
      designation: "VP And CMO",
    },
    {
      id: 2,
      img: "../assets/images/leader2.png",
      name: "Dr. Patricio Jugueta",
      designation: "President And CEO",
    },
    {
      id: 3,
      img: "../assets/images/leader3.png",
      name: "Willis Chavez",
      designation: "Co-Founder and CTO",
    },
  ];
  const wellnessCategories = [
    {
      id: 1,
      img: "../assets/images/weallnessCategories1.png",
      name: "Beauty",
      discription:
        "It has survived not only five centuries, but also leap into electronic typesetting, remaining",
    },
    {
      id: 2,
      img: "../assets/images/weallnessCategories2.png",
      name: "Wellness",
      discription:
        "It has survived not only five centuries, but also leap into electronic typesetting, remaining",
    },
    {
      id: 3,
      img: "../assets/images/weallnessCategories3.png",
      name: "Health Beverages",
      discription:
        "It has survived not only five centuries, but also leap into electronic typesetting, remaining",
    },
  ];

  return (
    <>
      {/* <section> */}
      <Container>
        <div className="aboutContainer">
          <div className="aboutCoverImgCon">
            <div className="aboutCoverImg">
              {/* <h2>ABOUT US</h2> */}
            </div>
          </div>
          <div className="row aboutSec2">
            <div className="col-lg-6 aboutSec2Div1">
              {/* <div className="aboutSecDivCon2">
                <div className="col-lg-5 col-12 aboutSecDivText2"> */}
              <div className="aboutSecDivText4">
                <div>
                  <h3>COMPANY PROFILE</h3>
                </div>
                <div>
                  <p>
                    {/* Founded by a group of vibrant and people oriented
                    entrepreneures, Maximum 88 Corporation endeavors to join the
                    ranks of major players in the Network Marketing Industry.
                    Our founder belive they can make an impact and do more as
                    entrepreneurs of network marketing by providing world class
                    superior products, and opening a generous business
                    opportunity that rewards and recognizes hard working
                    individuals. Their desire to offer the hghest possible pay
                    out in the industry, specially for distributors through
                    health optimization, led to the birh of Maximum 88
                    Corporation. */}
                    Maximum 88 Corporation, founded in 2018 by a team of passionate individuals dedicated to empowering well-being, is a leading Network Marketing company entering the exciting US market. We believe that true beauty radiates from within, and our mission is to provide you with the tools you need to achieve optimal health and enhance your natural radiance. Our Products At Maximum 88, we offer a comprehensive range of innovative health and beauty products designed to help you achieve your wellness goals.
                    <br />
                    <br />
                    Discover our selection of high-quality.
                    <br />
                    <br />
                    <b>Health Supplements: </b>Formulated with science-backed ingredients, our supplements are designed to support your overall health and well-being, from boosting your immune system to promoting healthy energy levels.
                    <br />
                    <br />
                    <b>Skincare Solutions: </b>Experience the difference of our luxurious skincare line, crafted with nourishing ingredients to cleanse, hydrate, and revitalize your skin.
                    <br />
                    <br />
                    Become Part of the Maximum 88 Family At Maximum 88, we believe in the power of collaboration. We offer a rewarding business opportunity for passionate individuals who share our commitment to empowering well-being. Join our network of like-minded entrepreneurs and build a thriving business with industry-leading compensation plans that recognize and reward your dedication. As a Maximum 88 distributor, you'll benefit from.
                    <br />
                    <br />
                    <b>Lucrative Earnings Potential: </b>Build a successful business and earn competitive commissions by sharing the power of Maximum 88 products with others.
                    <br />
                    <br />
                    <b>Supportive Community: </b>Gain access to ongoing training, marketing resources, and a network of supportive mentors and fellow distributors. Personal and Professional Growth: Embark on a journey of personal and professional development as you build your business and empower others to achieve their wellness goals. 
                    <br />
                    <br />
                  </p>
                </div>

                {/* </div>
                </div> */}
              </div>
            </div>
            <div className="col-lg-6 aboutCoverImgCon my-auto">
              <div className="s">
                <img
                  src={require("../assets/images/companyProfileImage.png")}
                  alt="Google"
                />
              </div>
            </div>
          </div>
          <div>
            <div className="leaderTextMain">
              <h3>Why Maximum 88? </h3>
            </div>
            <Row className="leadersCardDiv">
              <p>
              <b>World-Class Products: </b>Experience the difference of premium health and beauty solutions that deliver real results.
              <br /><br />
              <b>Lucrative Compensation: </b>Build a thriving business with the potential for high earnings and industry-leading rewards. 
              <br /><br />
              <b>Supportive Community: </b>Join a network of like-minded individuals who are passionate about health, beauty, and success. 
              <br /><br />
              <b>Empowering Opportunity: </b>Be part of a company dedicated to empowering individuals to achieve their full potential.
              </p>
            </Row>
          </div>
          <div>
            <div className="leaderTextMain">
              <h3>THE LEADERS AND CO-FOUNDERS</h3>
            </div>
            <Row className="leadersCardDiv">
              <Col lg={3} className="col-12">
                <div className="leadersCards">
                  <img
                    width={370}
                    src={require(`../assets/images/leader1.png`)}
                    alt="leader image"
                  />
                  <div className="leadersCardText">
                    <div>
                      <h4>Mark Anthony Dapiton</h4>
                      <p>VP & CMO</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={3} className="col-12">
                <div className="leadersCards">
                  <img
                    width={370}
                    src={require(`../assets/images/leader2.png`)}
                    alt="leader image"
                  />
                  <div className="leadersCardText">
                    <div>
                      <h4>Dr. Patricio Jugueta</h4>
                      <p>President & CEO</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={3} className="col-12">
                <div className="leadersCards">
                  <img
                    width={370}
                    src={require(`../assets/images/leader3.png`)}
                    alt="leader image"
                  />
                  <div className="leadersCardText">
                    <div>
                      <h4>Willis Chavez</h4>
                      <p>President for USA & CTO</p>
                    </div>
                    <PiInstagramLogo className="instaIcon" />
                  </div>
                </div>
              </Col>
              {/* // ))} */}
            </Row>
          </div>
          <div className="shopSec3Con">
            <div className="row shopSec3">
              <div className="col-lg-7 shopSex3H3">
                <h3>EXPLORE OUR WELLNESS CATEGORIES</h3>
              </div>
              <div className="col-lg-3 shopSex3Button">
                <Link to={'/shop'}>
                  <Button variant="Red">SHOP NOW</Button>
                </Link>
              </div>
            </div>
            <div className="cardswellness">
              <div className="row cardsWellnesssDiv">
                {wellnessCategories.map((item , index) => (
                  <div className="col-lg-4 col-12" key={index}>
                    <Card className="wellnessCategoriesCards mt-3">
                      <img
                        variant="top"
                        src={require(`../assets/images/weallnessCategories${item.id}.png`)}
                      />
                      <Card.Body>
                        <Card.Title className="wellnessCategoriesCardsTitle">
                          {item.name}
                        </Card.Title>
                        <Card.Text>
                          {item.discription}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Container>
      {/* </section> */}
    </>
  );
};

export default Main;
