import React, { useEffect, useState } from "react";
import { Container, Form, Button, Modal, Spinner } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const Main = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [products, setProducts] = useState([]);
  const [price, setPrice] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const packageId = params.get('id');
  const ref = params.get('ref');
  const navigate = useNavigate();
  const randomUserIdGuest = sessionStorage.getItem("randomUserId")

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    address: "",
    referral: ref != '' && ref != null && ref != undefined ? ref : ''
  });
  const [errorMessage, setErrorMessage] = useState("");
  const handleShow = (message) => {
    setErrorMessage(message);
    setShowPopup(true);

  };
  const handleClose = () => setShowPopup(false);

  const handleRegistration = async (event) => {
    event.preventDefault();
    const productId = [];
    const productQuantity = [];
    for (let i = 0; i < products.length; i++) {
      // const element = array[i];
      productId.push(products[i].product_id)
      productQuantity.push(products[i].quantity)
    }
    try {
      const response = await axios.post(
        "https://max88backend.testingwebsitelink.com/api/first_member",
        {
          first_name: formData.first_name,
          last_name: formData.last_name,
          email: formData.email,
          password: formData.password,
          role: 'member',
          referral_code: formData.referral,
          address: formData.address,
          product_id: productId,
          package_id: packageId,
          quantity: productQuantity
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      // navigate("/login");
      sessionStorage.setItem("referral_code", response.data.data.referral_code);
      sessionStorage.setItem("role", response.data.data.role);

      sessionStorage.setItem("userId", response.data.data.id);
      sessionStorage.setItem("userToken", response.data.data.token);
      sessionStorage.setItem("firstName", response.data.data.first_name);
      sessionStorage.setItem("paymentStatus", response?.data?.data?.payment_status);
      sessionStorage.removeItem("randomUserId");
      toast.success("Register successfully");

      navigate("/checkout");

    } catch (error) {
      handleShow(error?.response?.data?.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const fetchData = async () => {
    try {
      const token = sessionStorage.getItem("userToken");

      const response = await axios.get(`https://max88backend.testingwebsitelink.com/api/gest_view_order?gest_user_id=${randomUserIdGuest}`, {
        headers: {
          'Accept': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      setIsLoading(false);

      setProducts(response.data.data.cart);
      setPrice(response.data.data);
    } catch (error) {
      setIsLoading(false);
      handleShow(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const userId = sessionStorage.getItem('userId')
  useEffect(() => {
    if (userId) {
      navigate('/')
    } else {
      setIsLoading(false)
    }
  }, []);
  return (
    <>
      {isLoading ?
        <div className="loader-container text-center loader-container-shop">
          <Spinner animation="border" role="status"></Spinner>
        </div>
        :
        <Container className="my-8">
          <div className="row login-div">
            <div className="col-12">
              <div className="register-left-div">
                <div className="login-left-img-div-2-new">
                  <img
                    width={60}
                    src={require("../assets/logo/f-logo.png")}
                    alt="Google"
                  />
                </div>
                <div>
                  <h4 className="logo-text-b">MAXIMUM 88</h4>
                  <h4 className="logo-text-2-b">CORPORATION</h4>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <Form className="row register-sec" onSubmit={handleRegistration}>
                <h2>Personal Information</h2>
                <Form.Group className="mb-3" controlId="formFirstName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter First Name"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formLastName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Last Name"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="referral">
                  <Form.Label>Referal Code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="********"
                    name="referral"
                    value={formData.referral}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formPassword">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Address"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formTerms">
                  <Form.Check
                    type="checkbox"
                    label="I agree to all the terms and conditions"
                    name="acceptTerms"
                    required
                    feedback="I agree to all the terms and conditions."
                    className="custom-checkbox"
                  />
                </Form.Group>
                <Form.Group>
                  <Button variant="danger" className="col-4" type="submit">
                    SIGN UP
                  </Button>
                </Form.Group>
                <div className="row register-btns">
                </div>
              </Form>
            </div>
          </div>
          <Modal show={showPopup} onHide={handleClose} className="error-modal">
            <Modal.Header closeButton>
              <Modal.Title>Error</Modal.Title>
            </Modal.Header>
            <Modal.Body>{errorMessage}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      }
      <Modal show={showPopup} onHide={handleClose} className="error-modal">
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorMessage}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            className="error-button-close"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Main;
