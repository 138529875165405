import React from 'react'
import Main from '../LoginComponent/Main'

const Login = () => {
  return (
    <>
      <Main />
    </>
  )
}

export default Login
