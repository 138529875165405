import React from 'react'
import Main from '../AddToCartGuestComponent/Main'

const AddToCartGuest = () => {
  return (
    <>
      <Main/>
    </>
  )
}

export default AddToCartGuest
