import { useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Login from '../screens/Login';
import PreferredCustomer from '../screens/PreferredCustomer';
import { CSSTransition, TransitionGroup } from 'react-transition-group';


const Main = () => {
    const [key, setKey] = useState('home');

    return (
      <>
      {/* <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3 log-sin-tabs"
      >
        <Tab eventKey="home" title="LOGIN AS A CUSTOMER" className='tab-switch tab-switch-2 tab-switch-l'> */}
          <Login className="tabs-comp" />
        {/* </Tab>
        <Tab eventKey="profile" title="LOGIN AS A DISTRIBUTER" className='tab-switch tab-switch-2 tab-switch-r'>
        <Login className="tabs-comp" />
        </Tab>
      </Tabs> */}
      </>
    )
}

export default Main
