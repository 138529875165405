import React from 'react'

const BannerMain = () => {
  return (
    <>
      <div className='bannerBody'>
        <h5 className='bannerText'>
            Discover the best you,  inside and out, with our integrated beauty and wellness products.
        </h5>
      </div>
    </>
  )
}

export  {BannerMain}
