import React, { useEffect, useState } from "react";
import { Form, Button, Container, Modal, Spinner } from "react-bootstrap";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const Main = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const handleShow = (message) => {
    setErrorMessage(message);
    setShowPopup(true);
  };
  const handleClose = () => setShowPopup(false);
  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const token = "dRupO8KOGDUWUoEXVY7kwMQlmSMuNbywkBAkIrHYd01ac90b";
      const response = await axios.post(
        "https://max88backend.testingwebsitelink.com/api/login",
        {
          email: formData.email,
          password: formData.password,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      sessionStorage.removeItem("userId");
      sessionStorage.setItem("referral_code", response.data.data.referral_code);
      sessionStorage.setItem("role", response.data.data.role);
      sessionStorage.removeItem("randomUserId");
      if (response.data.data.is_varified === 0) {
        navigate(`/otp/${formData.email}`)
      } else {
        sessionStorage.setItem("userId", response.data.data.id);
        sessionStorage.setItem("userToken", response.data.data.token);
        sessionStorage.setItem("firstName", response.data.data.first_name);
        sessionStorage.removeItem("randomUserId");
        sessionStorage.setItem("role", response.data.data.role);
        sessionStorage.setItem("paymentStatus", response?.data?.data?.payment_status);
        toast.success("Login successfully");
        window.location.href = "/";
      }
    } catch (error) {
      handleShow(error?.response?.data?.message);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(prevShowPassword => !prevShowPassword);
  };

  const [isLoading, setIsLoading] = useState(true);
  const userId = sessionStorage.getItem('userId')
  const navigate = useNavigate()
  useEffect(() => {
    if (userId) {
      navigate('/')
    } else {
      setIsLoading(false)
    }
  }, []);

  return (
    <>
      {isLoading ?
        <div className="loader-container text-center loader-container-shop">
          <Spinner animation="border" role="status"></Spinner>
        </div>
        :
        <Container className="login-con">
          <div className="row login-div">
            <div className="col-lg-6">
              <div className="login-left-div">
                <div className="login-left-img-div">
                  <img
                    width={60}
                    src={require("../assets/logo/f-logo.png")}
                    alt="Google"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <Form className="row login-sec" onSubmit={handleLogin}>
                <h2>WELCOME BACK!</h2>
                <p className="login-sec-p">
                  Login
                </p>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>E-mail</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="example@example.com"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <div style={{ position: "relative" }}>
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                    />
                    <Button
                      variant="link"
                      onClick={togglePasswordVisibility}
                      style={{
                        position: "absolute",
                        right: 10,
                        transform: "translateY(20%)",
                        padding: "0",
                      }}
                    >
                      {showPassword ? <FaEyeSlash className="show-icon" /> : <FaEye className="show-icon" />}
                    </Button>
                  </div>
                </Form.Group>
                <div className="forgot-rem">
                  <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Remember me" />
                  </Form.Group>
                  <Link to="/forgotpassword" className="join-us-p">Forgot password?</Link>
                </div>
                <Button

                  variant="danger"
                  type="submit"
                  className="col-4"
                >
                  Login
                </Button>
                <p className="join-us-text">
                  Not Registered? <Link to="/joinus" className="join-us-p">Join us</Link>
                </p>
              </Form>
            </div>
          </div>
          <Modal show={showPopup} onHide={handleClose} className="error-modal">
            <Modal.Header closeButton>
              <Modal.Title>Error</Modal.Title>
            </Modal.Header>
            <Modal.Body>{errorMessage}</Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                className="error-button-close"
                onClick={handleClose}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      }
      <Modal show={showPopup} onHide={handleClose} className="error-modal">
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorMessage}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            className="error-button-close"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Main;
