import React from 'react'
import Main from '../RegistrationComponent/Main'

const Register = () => {
  return (
    <>
      <Main />
    </>
  )
}

export default Register
