import React from 'react'
import Main from '../OtpForgetComponent/Main'

const OtpForgot = () => {
  return (
    <>
      <Main />
    </>
  )
}

export default OtpForgot
