import React from 'react'
import Main from '../CheckoutGuestComponent/Main'

const CheckoutGuest = ({getCartCount , setShowCart}) => {
  return (
    <>
      <Main getCartCount={getCartCount} setShowCart={setShowCart}/>
    </>
  )
}

export default CheckoutGuest
