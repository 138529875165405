import React from 'react';
import { Link } from 'react-router-dom';
import './SideMenu.css'; // Import your CSS file for styling
import { Table } from 'react-bootstrap';
import { GiCancel } from 'react-icons/gi';

const SideMenu = ({ isOpen, toggleMenu, addedProduct }) => {
  return (
    <></>
  );
};

export default SideMenu;
