import React from 'react'
import { Container } from 'react-bootstrap'

const NotFoundPage = () => {
  return (
    <>
      <Container>
        <div>
            <h1>
            Page Not Found
            </h1>
        </div>
        <div>
            <p>
            We could not find what you were looking for.
            </p>
            <p>
            Please contact the owner of the site that linked you to the original URL and let them know their link is broken.
            </p>
        </div>
      </Container>
    </>
  )
}

export default NotFoundPage
