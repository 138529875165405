import React from 'react'
import Main from '../ForgetPassword/Main'

const ForgetPassword = () => {
  return (
    <>
      <Main />
    </>
  )
}

export default ForgetPassword
