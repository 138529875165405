import React, {useEffect, useState} from "react";
import { Form, Button, Container, Modal } from "react-bootstrap";
import { Link, useNavigate  } from "react-router-dom";

const Main = () => {
  return (
    <>
            <Container className="login-con">
      <div className="row login-div">
        <div className="col-lg-6">
          <div className="login-left-div">
            <div className="login-left-img-div">
              <img
                width={60}
                src={require("../assets/logo/f-logo.png")}
                alt="Google"
              />
            </div>
            <div>
              <h4 className="logo-text-b">MAXIMUM 88</h4>
              <h4 className="logo-text-2-b">CORPORATION</h4>
            </div>
          </div>
        </div>
        <div className="col-lg-6 my-auto">
          <Form className="row login-sec">
            <h2>CONFIRM PASSWORD</h2>
            <p>
              <span>Please enter your new password</span>
            </p>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Enter Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter your new password"
                name="password"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm your new password"
                name="password"
              />
            </Form.Group>
            <Button  variant="danger" className="col-12">
              Submit
            </Button>
          </Form>
        </div>
      </div>
      <Modal className="error-modal">
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="error-button-close" >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
    </>
  )
}

export default Main
