import React from 'react'
import Hero from '../HomeComponent/Hero'
import SecondSec from '../HomeComponent/SecondSec'
import Banner from './Banner'

const Home = () => {
  return (
    <>
      <Banner/>
      <Hero/>
      <SecondSec/>
    </>
  )
}

export default Home
