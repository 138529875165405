import React, {useState} from "react";
import { Container, Form, Button, Modal} from "react-bootstrap";
import { Link, useNavigate  } from 'react-router-dom';
import axios from 'axios';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const Main = () => {
  
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(prevShowPassword => !prevShowPassword);
  };
  return (
    <>
      <Container className="my-8">
      <div className="row login-div">
        <div className="col-12">
          <div className="register-left-div">
            <div className="login-left-img-div-s">
              <img
                width={60}
                src={require("../assets/logo/f-logo.png")}
                alt="Google"
              />
            </div>
            <div>
                <h4 className="logo-text-b">MAXIMUM 88</h4>
                <h4 className="logo-text-2-b">CORPORATION</h4>
              </div>
          </div>
        </div>
        <div className="col-lg-6">
          <Form className="row register-sec" >
            <h2>Join us as a Preferred Customer</h2>
            <Form.Group className="mb-3" controlId="formFirstName">
              <Form.Label> Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your Name"
                name="first_name"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label> Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="example@example.com"
                name="email"
              /> 
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <div style={{ position: "relative" }}>
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    name="password"
                  />
                  <Button
                    variant="link"
                    style={{
                      position: "absolute",
                      right: 10,
                      transform: "translateY(20%)",
                      padding: "0",
                    }}
                  >
                    {showPassword ? <FaEyeSlash className="show-icon" /> : <FaEye className="show-icon"/>}
                  </Button>
                </div>
            <Button variant="danger" className="col-4 mt-4" type="submit">
                Submit
              </Button>
              </Form.Group>
            <div className="row register-btns">
            </div>
            <div className="row register-btns">
              <p>Already Registered? <Link to="/login" className="join-us-p">Login</Link></p>
            </div>
          </Form>
        </div>
      </div>
    </Container>
    </>
  );
};

export default Main;

