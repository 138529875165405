import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Container, Form, Button, Modal, Row, Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const Main = () => {
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    otp: "",
  });

  const [errorMessage, setErrorMessage] = useState("");

  const handleShow = (message) => {
    setErrorMessage(message);
    setShowPopup(true);
  };

  const handleClose = () => setShowPopup(false);

  const handleSendOtp = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        "https://max88backend.testingwebsitelink.com/api/send_otp_rset_password",
        {
          email: formData.email,
        },
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        }
      );
      if (response.data) {
        navigate(`/otpforgot/${formData.email}`);
        toast.success("OTP Sended Successfully");
      } else {

      }
    } catch (error) {
      handleShow(error?.response?.data?.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const { email } = useParams();
  const [isLoading, setIsLoading] = useState(true)
  const userId = sessionStorage.getItem('userId')
  useEffect(() => {
    if (userId) {
      navigate('/')
    } else {
      setIsLoading(false)
    }
  }, []);

  return (
    <>
      {isLoading ?
        <div className="loader-container text-center loader-container-shop">
          <Spinner animation="border" role="status"></Spinner>
        </div>
        :
        <Container className="login-con">
          <Row className="login-div">
            <div className="col-lg-6">
              <div className="login-left-div">
                <div className="login-left-img-div">
                  <img
                    width={60}
                    src={require("../assets/logo/f-logo.png")}
                    alt="Google"
                  />
                </div>
                <div>
                  <h4 className="logo-text-b">MAXIMUM 88</h4>
                  <h4 className="logo-text-2-b">COOPERATION</h4>
                </div>
              </div>
            </div>
            <div className="col-lg-6 my-auto">
              <Form className="row register-sec" onSubmit={handleSendOtp}>
                <h2>FORGET PASSWORD RESET</h2>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>E-mail</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="E-mail"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  <Button variant="danger" className="col-4 mt-4" type="submit">
                    Submit
                  </Button>
                </Form.Group>
              </Form>
            </div>
          </Row>
          <Modal show={showPopup} onHide={handleClose} className="error-modal">
            <Modal.Header closeButton>
              <Modal.Title>Error</Modal.Title>
            </Modal.Header>
            <Modal.Body>{errorMessage}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose} className="error-button-close">
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      }
      <Modal show={showPopup} onHide={handleClose} className="error-modal">
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorMessage}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            className="error-button-close"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Main;
