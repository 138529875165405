import React from 'react'
import Main from "../ResetPassword/Main"

const ResetPassword = () => {
  return (
    <>
      <Main />
    </>
  )
}

export default ResetPassword
