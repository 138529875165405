import React from 'react'
import ProductDetailsPage from '../ProductDetailComponent/ProductDetailsPage'

const ProductDetail = ({getCartCount}) => {
  return (
    <>
      <ProductDetailsPage getCartCount={getCartCount} />
    </>
  )
}

export default ProductDetail
