import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  Button,
  Container,
  Spinner,
  Modal,
} from "react-bootstrap";
import { FaStar } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import Slider from "react-slick";

const Main = ({ getCartCount }) => {
  const [category, setCategory] = useState([]);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const userId = sessionStorage.getItem("userId");
  const role = sessionStorage.getItem("role");
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const ref = params.get('ref');
  if (ref != null) {
    localStorage.setItem('ref', ref)
  }
  console.log(typeof ref, 'refref');

  const [showPopup, setShowPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleShow = (message) => {
    setErrorMessage(message);
    setShowPopup(true);
  };

  const handleClose = () => setShowPopup(false);

  const generateUserId = () => {
    const randomUserId = Math.floor(Math.random() * 1000000);
    sessionStorage.setItem("randomUserId", randomUserId);
    return randomUserId;
  };
  const randomUserIdGuest = sessionStorage.getItem("randomUserId") || generateUserId();

  var settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
  };

  const addToCart = async (id) => {
    try {
      const token = sessionStorage.getItem("userToken");
      const response = await axios.post(
        "https://max88backend.testingwebsitelink.com/api/add_to_cart",
        {
          quantity: 0,
          product_id: id,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      getCartCount('click')
    } catch (error) {
      handleShow(error?.response?.data?.message);
    }
  };

  const addToCartGuest = async (id) => {
    try {
      const token = sessionStorage.getItem("userToken");
      const response = await axios.post(
        "https://max88backend.testingwebsitelink.com/api/gest_add_to_cart",
        {
          quantity: 0,
          product_id: id,
          gest_user_id: randomUserIdGuest
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      getCartCount('click')
    } catch (error) {
      handleShow(error?.response?.data?.message);
    }
  };
  useEffect(() => {
    const fetchCategoryData = async () => {
      try {
        const token = localStorage.getItem(
          "ihQFBEA0aED0fTxNKSd4gPi9jDXgIVh4YR13CoFE"
        );
        const response = await axios.get(
          "https://max88backend.testingwebsitelink.com/api/category",
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCategory(response.data.category);
      } catch (error) {
        handleShow(error?.response?.data?.message);
      }
    };
    handleFilterAll();
    fetchCategoryData();
  }, []);

  const handleFilter = async (categoryId) => {
    try {
      const token = localStorage.getItem(
        "ihQFBEA0aED0fTxNKSd4gPi9jDXgIVh4YR13CoFE"
      );

      const response = await axios.get(
        `https://max88backend.testingwebsitelink.com/api/category_vis_products/${categoryId}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setProducts(response.data.category[0].product);
      setIsLoading(false);
    } catch (error) {
      handleShow(error?.response?.data?.message);
      setIsLoading(false);
    }
  };
  const handleFilterAll = async () => {
    try {
      const token = localStorage.getItem(
        "ihQFBEA0aED0fTxNKSd4gPi9jDXgIVh4YR13CoFE"
      );

      const response = await axios.get(
        `https://max88backend.testingwebsitelink.com/api/products`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setProducts(response.data.products);
      setIsLoading(false);
    } catch (error) {
      handleShow(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  const sidebarRef = useRef(null);
  const bannerRef = useRef(null);

  const handleScroll = () => {
    if (!sidebarRef.current || !bannerRef.current) return;
    const bannerHeight = bannerRef.current.offsetHeight;
    if (window.scrollY > bannerHeight) {
      sidebarRef.current.classList.add("sticky-sidebar");
    } else {
      sidebarRef.current.classList.remove("sticky-sidebar");
    }
  };
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem(
          "ihQFBEA0aED0fTxNKSd4gPi9jDXgIVh4YR13CoFE"
        );

        const response = await axios.get(
          "https://max88backend.testingwebsitelink.com/api/slider",
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setData(response.data.sliderImage);
      } catch (error) {
        handleShow(error?.response?.data?.message);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const data1 = [
    {
      id: 1,
      name: "VERT SOAP",
      loyaltyDiscount: 5.3,
      retail: 5.3,
      price: 7.6,
      img: require("../assets/images/Rectangle 21990 (1).png"),
      description:
        "Your 8-in-1 All-Natural Green Oasis for Glowing Skin. Unleash the power of nature with M88 Vert, the all-natural green soap that pampers your skin with the goodness of 8 powerhouse ingredients. This luxurious formula cleanses deeply, leaving you feeling refreshed and invigorated.",
    },
    {
      id: 2,
      name: "ROGUE SOAP",
      loyaltyDiscount: 5.3,
      retail: 5.3,
      price: 7.6,
      img: require("../assets/images/banne2rouge1.png"),
      description:
        "Embrace a visibly brighter and healthier complexion with M88 Rouge, the all-natural 8-in-1 red soap. This luxurious formula combines the finest extracts from 8 powerful red fruits and flowers for a transformative skincare experience.",
    },
    {
      id: 4,
      name: "GLUTAGEN SOAP",
      loyaltyDiscount: 5.3,
      retail: 15.7,
      price: 7.6,
      img: require("../assets/images/Rectangle 21990 (1).png"),
      description:
        "Reveal a luminous, even-toned you with M88 Glutagen Soap! This luxurious bar is formulated with a powerful trio of skin-loving ingredients to cleanse, brighten, and revitalize your complexion.",
    },
  ];
  const data2 = [
    {
      id: 5,
      name: "MAXIJUICE",
      loyaltyDiscount: 11,
      retail: 42.8,
      price: 15.7,
      img: require("../assets/images/Rectangle 21990 (2).png"),
      description:
        "Maxijuice is a delicious and powerful blend of nature's finest ingredients, designed to supercharge your well-being. Packed with the goodness of exotic fruits like Acai, Buah Merah, and Guyabano, along with nourishing greens like barley, moringa, and wheatgrass, Maxijuice is your daily dose of vibrant health.",
    },
    {
      id: 6,
      name: "PPAR Plus +",
      loyaltyDiscount: 31,
      retail: 45.05,
      price: 44.28,
      img: require("../assets/images/Rectangle 21990 (2).png"),
      description:
        "PPAR Plus + stands as a groundbreaking whole-food supplement that harnesses the potent power of marine ingredients to optimize your health. This unique blend combines marine microalgae such as Chlorella and Spirulina with the targeted benefits of Chlorella PPAR extract. Enriched with Astaxanthin, a powerful antioxidant derived from microalgae, PPAR Plus + provides unparalleled cellular protection. Additionally, it includes Bacillus Coagulans, a beneficial probiotic strain, to ensure a healthy gut microbiome, as well as Fucoidan, a marine polysaccharide with promising health benefits. Formulated without added sugars, artificial sweeteners, or unnecessary fillers, PPAR Plus + guarantees that you receive the pure power of the ocean in every serving.",
    },
    {
      id: 8,
      name: "MAXDIET",
      loyaltyDiscount: 33.99,
      retail: 18.19,
      price: 47.99,
      img: require("../assets/images/Rectangle 21990 (2).png"),
      description:
        "Feeling sluggish or weighed down? Unleash Your Inner Detox with a Refreshing Fizz!",
    },
  ];
  const data3 = [
    {
      id: 9,
      name: "MAXIGOLD",
      loyaltyDiscount: 12.7,
      retail: 18.19,
      price: 18.19,
      img: require("../assets/images/Rectangle 21990 (2).png"),
      description:
        "Unleash the vibrant power of turmeric in every cup with MAXIGOLD, the incredibly delicious and healthy coffee you've been waiting for. This unique blend goes beyond your average cup of joe, infused with a symphony of nature's most potent ingredients to support your well-being from the inside out.",
    },
    {
      id: 10,
      name: "MAXIWHITE",
      loyaltyDiscount: 35.36,
      retail: 50.5,
      price: 84.35,
      img: require("../assets/images/Rectangle 21990 (4).png"),
      description:
        "Maxiwhite Sakura is a powerful ingestible skincare supplement designed to unlock your skin's full potential. This luxurious formula combines the best of nature's bounty with cutting-edge science to deliver a radiant, youthful, and healthy complexion.",
    },
    {
      id: 11,
      name: "TAMARAW Plus",
      loyaltyDiscount: 7.5,
      retail: 10.67,
      price: 10.67,
      img: require("../assets/images/Rectangle 21990 (4).png"),
      description:
        "Tamaraw Plus is a premium adult supplement designed to empower you to achieve your full potential. This powerful blend combines the wisdom of ancient traditions with modern science to create a natural formula that supports overall well-being and vitality.",
    },
  ];
  const data6 = [
    {
      id: 14,
      name: "Starter Kit",
      loyaltyDiscount: 12.7,
      retail: 50.5,
      price: 18.19,
      img: require("../assets/images/Starter Kit img.jpg"),
      description:
        "Choose your products at MEMBERS PRICE amounting to not more than 70$.",
    },
    {
      id: 15,
      name: "Ultim8 Package",
      loyaltyDiscount: 35.36,
      retail: 50.5,
      price: 84.35,
      img: require("../assets/images/Ultim8 Package img.jpg"),
      description:
        "Choose your products at MEMBERS PRICE amounting to not more than 328$.",
    },
  ];

  const mergedData = [...data1, ...data2, ...data3, ...data6];
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(9);
  const [sortedData, setSortedData] = useState(null);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const [selectedStars, setSelectedStars] = useState(0);

  const handleStarClick = (star) => {
    setSelectedStars(star);
  };

  const processDescription = (description) => {
    return description
      .replace(/<\/?b>/g, '') // Remove <b> tags
      .split('|')
      .map((part, index) => (
        <React.Fragment key={index}>
          <strong>{part.trim()}</strong>
          {index < description.split('|').length - 1 && <br />} {/* Add line break except for the last part */}
        </React.Fragment>
      ));
  };
  return (
    <>
      <Container >
        <div className="shopBannerImg" ref={bannerRef}>
          <Slider {...settings}>
            {data.map((banner, index) => (
              <div key={index}>
                <img
                  style={{ objectFit: "contain" }}
                  src={banner.image}
                  alt="Slide 1"
                />
              </div>
            ))}
          </Slider>
        </div>
        {isLoading ? (
          <div className="loader-container text-center loader-container-shop">
            <Spinner animation="border" role="status"></Spinner>
          </div>) :
          <>
            <div className="row shopcategoryDiv mt-3">
              <div className="col-lg-3 mt-2">
                <div className={`col dropdown-content1 `} ref={sidebarRef}>
                  <button onClick={() => handleFilterAll()}>
                    <p>All</p>
                  </button>
                  <>
                    {category.map((cat, index) => (
                      <>
                        <button key={index} onClick={() => handleFilter(cat.id)} className="w-100">
                          <p>{cat.tittle}</p>
                        </button>
                      </>
                    ))}
                  </>
                </div>
              </div>
              <div className="col-lg-9 mt-2">
                <div className="row">
                  {isLoading ? (
                    <div className="loader-container text-center">
                      <Spinner animation="border" role="status"></Spinner>
                    </div>
                  ) : (
                    <>
                      {products.length === 0 ? (
                        <h3>Products are not available</h3>
                      ) : (
                        <>
                          {products.map((item, index) => (
                            <div className="col-lg-4 col-md-4 col-12 mb-3" key={index}>
                              <div className="categoryProductCardLink">
                                <Card className="categoryProductCard">
                                  <Link to={`/productdetail/${item.id}`}>
                                    <div className="categoryProductCardImg">
                                      <img width={230} src={item.main_image} alt="Product" />
                                      {/* <img width={230} src={`https://max88backend.testingwebsitelink.com/${item.main_image}`} alt="Product" /> */}
                                    </div>
                                  </Link>
                                  <Card.Body className="categoryProductCardBody">
                                    <div className="card-body-buttom ">
                                      <Link to={`/productdetail/${item.id}`}>
                                        <div>
                                          <h4>{item.tittle}</h4>
                                          <div>
                                            <p>
                                              <p>{item.description.length > 30
                                                ? processDescription(`${item.description.substring(0, 49)} `)
                                                : processDescription(item.description)}<a className="textarrr" href="#">Read More..</a></p>
                                            </p>
                                          </div>
                                        </div>
                                        <div>
                                          <div className="categoryProductCardTextBtmh5">
                                            {role === 'member' && <h5><span className="line-true">${item.price}</span> <span> ${item?.member_price}</span></h5>}
                                            {role === 'costomer' && <h5><span className="line-true">${item.price}</span> <span> ${item?.customer_price}</span></h5>}
                                            {(role != 'costomer' && role != 'member') && <h5>${item.price}</h5>}
                                            {/* <h5>${item.price}</h5> */}
                                          </div>
                                          <div className="row categoryProductCardTextBtm">
                                            <div className="categoryProductCardTextBtm1">
                                              <h6>Item # 107890</h6>
                                              <div className="row categoryProductCardTextBtm2">
                                                <div className="cardRate">
                                                  {[...Array(5)].map((_, idx) => {
                                                    const starValue = idx + 1;
                                                    return (
                                                      <FaStar
                                                        key={idx}
                                                        className={
                                                          starValue <= selectedStars
                                                            ? "selected"
                                                            : ""
                                                        }
                                                        onClick={() =>
                                                          handleStarClick(starValue)
                                                        }
                                                      />
                                                    );
                                                  })}
                                                </div>
                                                <p>(02)</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </Link>
                                      {userId === null ? (
                                        <Button variant="danger" onClick={() => addToCartGuest(item.id)}>
                                          Add to Cart
                                        </Button>
                                      ) : (

                                        <Button variant="danger" onClick={() => addToCart(item.id)}>
                                          Add To Cart
                                        </Button>
                                      )}
                                      {/* <Button variant="danger" onClick={() => addToCart(item.id)}>
                                    Add To Cart
                                  </Button> */}
                                    </div>
                                  </Card.Body>
                                </Card>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <p className="footer-bottom-limne">These statements have not been evaluated by the Food and Drug Administration. These products are not intended to diagnose, treat, cure or prevent any disease</p>
          </>
        }
      </Container>

      <Modal show={showPopup} onHide={handleClose} className="error-modal">
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorMessage}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            className="error-button-close"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Main;
