import React from 'react'
import Main from '../SelectMaxPackComponent/Main'

const SlectMaxPack = () => {
  return (
    <>
      <Main />
    </>
  )
}

export default SlectMaxPack
