import React from 'react'
import Main from '../SingupAndSigninTab/Main'

const SignupAndSigninTab = () => {
  return (
    <>
      <Main />
    </>
  )
}

export default SignupAndSigninTab
