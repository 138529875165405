import React, { useEffect, useRef, useState } from "react";
import { Card, Button, Container, Spinner, Modal } from "react-bootstrap";
import { FaStar } from "react-icons/fa";
import axios from "axios";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";

const ShopNewMember = ({ getCartCount }) => {
    const [category, setCategory] = useState([]);
    const [products, setProducts] = useState([]);
    const [CartProducts, setCartProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingFull, setIsLoadingFull] = useState(false);
    const userId = sessionStorage.getItem("userId");
    const navigate = useNavigate()
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const packageId = params.get('id');
    const packagePrice = params.get('price');
    const packageName = params.get('name');
    const ref = params.get('ref');
    const sidebarRef = useRef(null);
    const bannerRef = useRef(null);
    const [showPopup, setShowPopup] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleShow = (message) => {
        setErrorMessage(message);
        setShowPopup(true);
    };

    const handleClose = () => setShowPopup(false);

    const generateUserId = () => {
        const randomUserId = Math.floor(Math.random() * 1000000);
        sessionStorage.setItem("randomUserId", randomUserId);
        return randomUserId;
    };
    const randomUserIdGuest = sessionStorage.getItem("randomUserId") || generateUserId();

    function matchAndSetQuantity(array1, array2) {
        const map = {};
        array2.forEach(item => {
            map[item.product_id] = item.quantity;
        });

        const result = array1.map(item => ({
            ...item,
            quantity: map[item.id] || 0
        }));
        setProducts(result)
        setIsLoading(false)
        setIsLoadingFull(false)
    }

    useEffect(() => {
        const fetchCategoryData = async () => {
            try {
                const token = localStorage.getItem(
                    "ihQFBEA0aED0fTxNKSd4gPi9jDXgIVh4YR13CoFE"
                );
                const response = await axios.get(
                    "https://max88backend.testingwebsitelink.com/api/category",
                    {
                        headers: {
                            Accept: "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setCategory(response.data.category);
            } catch (error) {
                handleShow(error?.response?.data?.message);
            }
        };
        handleFilterAll();
        fetchCategoryData();
    }, []);

    const handleFilterAll = async () => {
        try {
            const token = localStorage.getItem(
                "ihQFBEA0aED0fTxNKSd4gPi9jDXgIVh4YR13CoFE"
            );

            const response = await axios.get(
                `https://max88backend.testingwebsitelink.com/api/products`,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            fetchCartData(response.data.products)
        } catch (error) {
            handleShow(error?.response?.data?.message);
            setIsLoading(false);
        }
    };

    const handleScroll = () => {
        if (!sidebarRef.current || !bannerRef.current) return;
        const bannerHeight = bannerRef.current.offsetHeight;
        if (window.scrollY > bannerHeight) {
            sidebarRef.current.classList.add("sticky-sidebar");
        } else {
            sidebarRef.current.classList.remove("sticky-sidebar");
        }
    };
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem(
                    "ihQFBEA0aED0fTxNKSd4gPi9jDXgIVh4YR13CoFE"
                );

                const response = await axios.get(
                    "https://max88backend.testingwebsitelink.com/api/slider",
                    {
                        headers: {
                            Accept: "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                setData(response.data.sliderImage);
            } catch (error) {
                handleShow(error?.response?.data?.message);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(9);
    const [selectedStars, setSelectedStars] = useState(0);
    const [price, setPrice] = useState([]);
    const [cartPrice, setCartPrice] = useState([]);

    const handleStarClick = (star) => {
        setSelectedStars(star);
    };

    const processDescription = (description) => {
        return description
            .replace(/<\/?b>/g, '') // Remove <b> tags
            .split('|')
            .map((part, index) => (
                <React.Fragment key={index}>
                    <strong>{part.trim()}</strong>
                    {index < description.split('|').length - 1 && <br />} {/* Add line break except for the last part */}
                </React.Fragment>
            ));
    };
    const fetchData = async () => {
        try {
            const token = sessionStorage.getItem("userToken");

            const response = await axios.get(`https://max88backend.testingwebsitelink.com/api/gest_view_order?gest_user_id=${randomUserIdGuest}`, {
                headers: {
                    'Accept': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            });

            setPrice(response.data.data);
        } catch (error) {
            setIsLoading(false);
            handleShow(error?.response?.data?.message);
        }
    };

    const addToCartGuest = async (id, quantity) => {
        setIsLoadingFull(true)
        try {
            const token = sessionStorage.getItem("userToken");
            const response = await axios.post(
                "https://max88backend.testingwebsitelink.com/api/gest_add_to_cart",
                {
                    quantity: quantity,
                    product_id: id,
                    package_id: +packageId,
                    gest_user_id: randomUserIdGuest,
                    // type: actionType
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            fetchData()
            handleFilterAll()
        } catch (error) {
            fetchData()
            handleFilterAll()
            setIsLoadingFull(false)
            handleShow(error?.response?.data?.message);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const fetchCartData = async (ProductList) => {
        try {
            const token = sessionStorage.getItem("userToken");
            const response = await axios.get(`https://max88backend.testingwebsitelink.com/api/gest_view_order?gest_user_id=${randomUserIdGuest}`, {
                headers: {
                    'Accept': 'application/json',
                    Authorization: `Bearer ${token}`
                }
            });
            setCartProducts(response.data.data.cart);
            matchAndSetQuantity(ProductList, response.data.data.cart)
            setCartPrice(response.data.data);
        } catch (error) {
            setIsLoading(false);
            handleShow(error?.response?.data?.message);
        }
    };

    useEffect(() => {
        if (userId) {
            navigate('/')
        } else {
            setIsLoading(false)
        }
    }, []);

    const increamnetObject = (id, operation, index) => {
        const updatedProducts = [...products];
        if (products[index].quantity >= 0) {
            if (operation === 'minus') {
                if (products[index].quantity > 0) {
                    updatedProducts[index].quantity = updatedProducts[index].quantity - 1;
                }
            } else {
                updatedProducts[index].quantity = updatedProducts[index].quantity + 1;

            }
            setProducts(updatedProducts)
        }
    }

    return (
        <>

            {isLoading ?
                <div className="loader-container text-center loader-container-shop">
                    <Spinner animation="border" role="status"></Spinner>
                </div>
                :
                <>
                    {isLoadingFull &&
                        <div className="loader-container text-center loader-container-shop"
                            style={{
                                width: '100vw',
                                height: '100vh',
                                position: 'fixed',
                                top: 0,
                                left: 'auto',
                                background: 'rgba(255, 255, 255, 0.8)',
                                zIndex: '9999',
                            }}
                        >
                            <Spinner animation="border" role="status"></Spinner>
                        </div>
                    }
                    <Container>
                        {isLoading ? (
                            <div className="loader-container text-center loader-container-shop">
                                <Spinner animation="border" role="status"></Spinner>
                            </div>) :
                            <>
                                <div className="row shopcategoryDiv mt-3">
                                    <div className="col-lg-12 mt-2">
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <div className="title-price-package">
                                                    <div>
                                                        <ul>
                                                            <li>{packageName}:</li>
                                                            <li>${packagePrice}({((price?.discountPrice)-(price?.tax))?.toFixed(2)})</li>
                                                        </ul>
                                                    </div>
                                                    <div>Select Your Preferred Items</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-9">
                                                <div className="row">
                                                    {isLoading ? (
                                                        <div className="loader-container text-center">
                                                            <Spinner animation="border" role="status"></Spinner>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            {products.length === 0 ? (
                                                                <h3>Products are not available</h3>
                                                            ) : (
                                                                <>
                                                                    {products.map((item, index) => (
                                                                        <div className="col-lg-4 col-md-4 col-12 mb-3" key={index}>
                                                                            <div className="categoryProductCardLink">
                                                                                <Card className="categoryProductCard">
                                                                                    <Link >
                                                                                        <div className="categoryProductCardImg">
                                                                                            <img width={230} src={item.main_image} alt="Product" />
                                                                                        </div>
                                                                                    </Link>
                                                                                    <Card.Body className="categoryProductCardBody">
                                                                                        <div className="card-body-buttom ">
                                                                                            <Link >
                                                                                                <div>
                                                                                                    <h4>{item.tittle}</h4>
                                                                                                    <div>
                                                                                                            <p>{item.description.length > 30
                                                                                                                ? processDescription(`${item.description.substring(0, 49)} `)
                                                                                                                : processDescription(item.description)}<a className="textarrr" href="#">Read More..</a></p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <div className="categoryProductCardTextBtmh5">
                                                                                                        <h5><span className="line-true">${item.price}</span> <span> ${item?.member_price}</span></h5>
                                                                                                    </div>
                                                                                                    <div className="row categoryProductCardTextBtm">
                                                                                                        <div className="categoryProductCardTextBtm1">
                                                                                                            <h6>Item # 107890</h6>
                                                                                                            <div className="row categoryProductCardTextBtm2">
                                                                                                                <div className="cardRate">
                                                                                                                    {[...Array(5)].map((_, idx) => {
                                                                                                                        const starValue = idx + 1;
                                                                                                                        return (
                                                                                                                            <FaStar
                                                                                                                                key={idx}
                                                                                                                                className={
                                                                                                                                    starValue <= selectedStars
                                                                                                                                        ? "selected"
                                                                                                                                        : ""
                                                                                                                                }
                                                                                                                                onClick={() =>
                                                                                                                                    handleStarClick(starValue)
                                                                                                                                }
                                                                                                                            />
                                                                                                                        );
                                                                                                                    })}
                                                                                                                </div>
                                                                                                                <p>(02)</p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </Link>
                                                                                            <div className="inc-dec">
                                                                                                <ul>
                                                                                                    <li onClick={() => increamnetObject(item.id, 'minus', index)}><AiOutlineMinus /></li>
                                                                                                    <li>{item?.quantity}</li>
                                                                                                    <li onClick={() => increamnetObject(item.id, 'plus', index)} ><AiOutlinePlus /></li>
                                                                                                    {/* <li onClick={() => addToCartGuest(item.id, 'decrement' , index)}><AiOutlineMinus /></li>
                                                                                                    <li>{item?.quantity}</li>
                                                                                                    <li onClick={() => addToCartGuest(item.id, 'increment' , index)} ><AiOutlinePlus /></li> */}
                                                                                                </ul>
                                                                                            </div>
                                                                                            <div>
                                                                                                <button className="shop-add-to-cart" onClick={() => addToCartGuest(item.id, item?.quantity, index)}>Add to cart</button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Card.Body>
                                                                                </Card>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="member-shop-right">
                                                    <div className="r-top">
                                                        <h2>Order Summary</h2>
                                                    </div>
                                                    <div className="t-botton">
                                                        <div className="right-1">
                                                            <ul>
                                                                <li>Subtotal</li>
                                                                <li>${(+cartPrice?.finalPrice)}</li>
                                                            </ul>
                                                        </div>
                                                        <hr />
                                                        <div className="right-2">
                                                            <ul>
                                                                <li>Discount</li>
                                                                <li>${(cartPrice?.finalPrice - cartPrice?.discountPrice)?.toFixed(2)}</li>
                                                            </ul>
                                                        </div>
                                                        <hr />
                                                        <div className="right-3">
                                                            <ul>
                                                                <li>Estimated Total</li>
                                                                <li>${((+cartPrice?.discountPrice)-(+cartPrice?.tax))?.toFixed(2)}</li>
                                                            </ul>
                                                        </div>
                                                        <div className="right-4">
                                                            {((+cartPrice?.discountPrice) < (+packagePrice - 10)) ?
                                                                <button className={`${((+cartPrice?.discountPrice) < (+packagePrice - 10)) ? 'disable' : ''}`} disabled={+cartPrice?.discountPrice < (+packagePrice - 10)}>
                                                                    Next
                                                                </button>
                                                                :
                                                                ref != '' && ref != null && ref != undefined ?
                                                                <Link to={`/joinasamember?id=${packageId}&price=${packagePrice}&name=${packageName}&ref=${ref}`}>
                                                                    <button >
                                                                        Next
                                                                    </button>
                                                                </Link>
                                                                :
                                                                <Link to={`/joinasamember?id=${packageId}&price=${packagePrice}&name=${packageName}`}>
                                                                    <button >
                                                                        Next
                                                                    </button>
                                                                </Link>
                                                                
                                                            }
                                                        </div>
                                                        <div className="right-5">
                                                            <p>Exact discounts, promotions, delivery charges, taxes and other fees will be calculated during checkout</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </Container>
                </>
            }

            <Modal show={showPopup} onHide={handleClose} className="error-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                </Modal.Header>
                <Modal.Body>{errorMessage}</Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                        className="error-button-close"
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ShopNewMember;
