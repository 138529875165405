import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Form, Button, Modal, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const Main = () => {
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  const { emailverification } = useParams();
  const [formData, setFormData] = useState({
    otp: "",
  });
  const [errorMessage, setErrorMessage] = useState("");

  const handleShow = (message) => {
    setErrorMessage(message);
    setShowPopup(true);
  };

  const handleClose = () => setShowPopup(false);

  const handleVerifyOtp = async (event) => {
    event.preventDefault();
    try {
      const token = "dRupO8KOGDUWUoEXVY7kwMQlmSMuNbywkBAkIrHYd01ac90b";

      const response = await axios.post(
        "https://max88backend.testingwebsitelink.com/api/otp_varify",
        {
          email: emailverification,
          otp: formData.otp,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Verified Successfully");
      navigate("/login");
    } catch (error) {
      handleShow(error?.response?.data?.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [isLoading, setIsLoading] = useState(true)
  const userId = sessionStorage.getItem('userId')
  useEffect(() => {
    if (userId) {
      navigate('/')
    } else {
      setIsLoading(false)
    }
  }, []);

  return (
    <>
      {isLoading ?
        <div className="loader-container text-center loader-container-shop">
          <Spinner animation="border" role="status"></Spinner>
        </div>
        :
        <Container className="login-con">
          <Row className="login-div">
            <div className="col-lg-6">
              <div className="login-left-div">
                <div className="login-left-img-div">
                  <img
                    width={60}
                    src={require("../assets/logo/f-logo.png")}
                    alt="Google"
                  />
                </div>
                <div>
                  <h4 className="logo-text-b">MAXIMUM 88</h4>
                  <h4 className="logo-text-2-b">COOPERATION</h4>
                </div>
              </div>
            </div>
            <div className="col-lg-6 my-auto">
              <Form className="row login-sec" onSubmit={handleVerifyOtp}>
                <h2>OTP VERIFICATION</h2>
                <p>
                  <span>Please enter the OTP sent to {emailverification}</span>
                </p>
                <Form.Group className="mb-3" controlId="formBasicOtp">
                  <Form.Label>OTP</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter OTP"
                    name="otp"
                    value={formData.otp}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Button variant="danger" className="col-12" type="submit">
                  Verify OTP
                </Button>
              </Form>
            </div>
          </Row>
          <Modal show={showPopup} onHide={handleClose} className="error-modal">
            <Modal.Header closeButton>
              <Modal.Title>Error</Modal.Title>
            </Modal.Header>
            <Modal.Body>{errorMessage}</Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={handleClose}
                className="error-button-close"
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      }
      <Modal show={showPopup} onHide={handleClose} className="error-modal">
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorMessage}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            className="error-button-close"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Main;
