import React, { useState } from "react";
import axios from "axios";
import { Container, Form, Button, Modal, Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Main = () => {
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const { emailverification } = useParams(); 

  const handleShow = (message) => {
    setErrorMessage(message);
    setShowPopup(true);
  };

  const handleClose = () => setShowPopup(false);

  const handleSendOtp = async (event) => {
    event.preventDefault();
    if (formData.password != formData.confirmPassword) {
      handleShow("Passwords do not match. Please try again.");
      return;
    }
    try {
      const response = await axios.post(
        "https://max88backend.testingwebsitelink.com/api/reset_password",
        {
          email: emailverification,
          new_password: formData.password,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      navigate(`/login`);
    } catch (error) {
      handleShow(error?.response?.data?.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const { email } = useParams();

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <>
      <Container className="login-con">
        <Row className="login-div">
          <Col lg={12}>
            <div className="register-left-div">
              <div className="login-left-img-div-2">
                <img
                  width={60}
                  src={require("../assets/logo/f-logo.png")}
                  alt="Google"
                />
              </div>
              <div>
                <h4 className="logo-text-b">MAXIMUM 88</h4>
                <h4 className="logo-text-2-b">COOPERATION</h4>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <Form className="row register-sec" onSubmit={handleSendOtp}>
              <h2>Password Reset </h2>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <div style={{ position: "relative" }}>
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                    />
                    <Button
                      variant="link"
                      onClick={togglePasswordVisibility}
                      style={{
                        position: "absolute",
                        right: 10,
                        transform: "translateY(20%)",
                        padding: "0",
                      }}
                    >
                      {showPassword ? (
                        <FaEyeSlash className="show-icon" />
                      ) : (
                        <FaEye className="show-icon" />
                      )}
                    </Button>
                  </div>
                </Form.Group>
                <Form.Label className="mt-4">Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Confirm Password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                />
                <Button variant="danger" className="col-4 mt-4" type="submit">
                  Submit
                </Button>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Container>
      <Modal show={showPopup} onHide={handleClose} className="error-modal">
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorMessage}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            className="error-button-close"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      
    </>
  );
};

export default Main;
