import React from "react";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Container, Row, Modal } from "react-bootstrap";

const SecondSec = () => {

  const [data, setData] = useState(null);
  const userId = sessionStorage.getItem('userId')

  const [showPopup, setShowPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleShow = (message) => {
    setErrorMessage(message);
    setShowPopup(true);
  };

  const handleClose = () => setShowPopup(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('ihQFBEA0aED0fTxNKSd4gPi9jDXgIVh4YR13CoFE');
        const response = await axios.get('https://max88backend.testingwebsitelink.com/api/banner', {
          headers: {
            'Accept': 'application/json',
            Authorization: `Bearer ${token}`
          }
        });

        setData(response.data.banner);
      } catch (error) {
        handleShow(error?.response?.data?.message);
      }
    };

    fetchData();
  }, []);
  const contactRef = useRef(null);

  useEffect(() => {
    if (contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <>
      <div>
        <div className="secThree">
          <Container className="col sec3 secThreeBg">
            <div className="row  secDivCon3">
              <div className="col-lg-6 secDivText3">

              </div>
              <div className="col-lg-5 col-10 secDivText3" data-aos="fade-left">
                <div>
                  <h3>WHO WE ARE</h3>
                </div>
                <div>
                  <p>
                    At Maximum 88, we're passionate about empowering people to
                    achieve their wellness goals – both physically and
                    financially. Discover our amazing products designed to help
                    you thrive, and join our rewarding program for exclusive
                    benefits like discounts and rewards. We’re soft launching in
                    the USA this September – get ready for over-all wellness!
                  </p>
                </div>
                <div>
                  <Link to={'/about'}>
                  <Button variant="light">About Us</Button>
                  </Link>
                </div>
              </div>
            </div>
          </Container>
        </div>
        <section className="home-sec-4">
          <Container>
            <div className="sec4">
              <Row className="">

                <div className="col-lg-6 secDivText4" data-aos="fade-right">
                  <div className="secDivText4">
                    <div>
                      <h3>UNLOCK THE TROPICAL SECRETS TO WELLNESS</h3>
                    </div>
                    <div>
                      <p>
                        Maxijuice: A Supercharged Elixir for a Vibrant You
                      </p>
                    </div>

                  </div>
                </div>
                <div className="col-lg-6 secDicCovImg4" data-aos="fade-left">

                  <div className="textedflex">
                    <div className="textedflex-wrap">
                      <img
                        src={require("../assets/images/better_oppertunity_banner.png")}
                        alt="Google"
                      />
                      <Link to={"/productdetail/9"}>
                        <Button className="btttsdd" variant="Red">Learn More</Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </Row>
            </div>
            <div className="secFive row">
              <div className="">
                <div className="col-lg-12 secDivText5" data-aos="flip-up">
                  <div>
                    <h3>WE’VE FOUND GOLD!</h3>
                  </div>
                  <div>
                    <p>
                      Start your day like a champion –  MAXIGOLD: Your morning cup of energy and turmeric sunshine!
                    </p>
                  </div>
                </div>
                <Row style={{ justifyContent: 'center' }}>
                  <div className="col-lg-5 secDivImg" data-aos="fade-right">
                    <iframe
                      src="https://www.youtube.com/embed/cuiXIwBwnv4?si=GDHqrTSD3cD7CjS9"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                      allowFullScreen
                    ></iframe>
                  </div>
                  <div className="col-lg-4 secDivImg" data-aos="fade-left">
                    <img
                      src={require("../assets/images/banners/thrd1.png")}
                      alt="Google"
                    />
                  </div>
                </Row>
              </div>
            </div>
            <div className="sec6">
              <div className="row">
                <div className="col-lg-6 col-md-6 secDivImg6" data-aos="fade-right">
                  <img
                    src={require("../assets/images/join-us-home.jpg")}
                    alt="Google"
                  />
                </div>
                <div className="col-lg-6 col-md-6 my-auto sec6Sec" data-aos="fade-left">
                  <div className="row joinUsForm">
                    <div className="sec6Texth3">
                      <h3>Join the Maximum88 Family</h3>
                    </div>
                    <div className="sec6Textp">
                      <p>
                        Craft your dream life with Maximum 88 as you have the
                        potential to boost your income and ditch the rigid
                        schedule. Be your own boss and work when it fits your
                        world.
                      </p>
                    </div>
                    {userId ? null :
                    
                    <Link to="/joinus">
                      <div direction="horizontal" gap={2} className="">
                        <Button className="btn-light joinButtonMain">
                          JOIN US
                        </Button>
                      </div>
                    </Link>
                    }
                  </div>
                </div>
              </div>
            </div>
          </Container>

        </section>
      </div>
      <Modal show={showPopup} onHide={handleClose} className="error-modal">
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorMessage}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
            className="error-button-close"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SecondSec;
