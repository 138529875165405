import React, { useState, useRef } from "react";
import { Container, Button } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";


const ProductDetailsPage = () => {
  let [num, setNum] = useState(1);
  let incNum = () => {
    if (num < 10) {
      setNum(Number(num) + 1);
    }
  };
  let decNum = () => {
    if (num > 0) {
      setNum(num - 1);
    }
  };
  let handleChange = (e) => {
    setNum(e.target.value);
  };

  const customPagination = {
    renderBullet: function (index, className) {
      return `<span className="${className} paginationBullet" style="background-color: ${
        index === this.activeIndex ? "#c6282d" : "#c6282d"
      };"></span>`;
    },
  };
  let usrFeedback = [
    {
      image: "../assets/images/user-feedback-img1.png",
      data: "May 8, 2020",
      title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim arcu. Elementum felis magna pretium in tincidunt. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim arcu. Elementum felis magna pretium in tincidunt. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu.",
    },
    {
      image: "../assets/images/user-feedback-img1.png",
      data: "May 8, 2020",
      title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim arcu. Elementum felis magna pretium in tincidunt. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim arcu. Elementum felis magna pretium in tincidunt. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu.",
    },
    {
      image: "../assets/images/user-feedback-img1.png",
      data: "May 8, 2020",
      title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim arcu. Elementum felis magna pretium in tincidunt. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim arcu. Elementum felis magna pretium in tincidunt. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu.",
    },
    {
      image: "../assets/images/user-feedback-img1.png",
      data: "May 8, 2020",
      title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim arcu. Elementum felis magna pretium in tincidunt. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim arcu. Elementum felis magna pretium in tincidunt. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu.",
    },
    {
      image: "../assets/images/user-feedback-img1.png",
      data: "May 8, 2020",
      title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim arcu. Elementum felis magna pretium in tincidunt. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim arcu. Elementum felis magna pretium in tincidunt. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu.",
    },
    {
      image: "../assets/images/user-feedback-img1.png",
      data: "May 8, 2020",
      title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim arcu. Elementum felis magna pretium in tincidunt. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim arcu. Elementum felis magna pretium in tincidunt. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu.",
    },
    {
      image: "../assets/images/user-feedback-img1.png",
      data: "May 8, 2020",
      title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim arcu. Elementum felis magna pretium in tincidunt. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim arcu. Elementum felis magna pretium in tincidunt. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu.",
    },
    {
      image: "../assets/images/user-feedback-img1.png",
      data: "May 8, 2020",
      title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim arcu. Elementum felis magna pretium in tincidunt. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim arcu. Elementum felis magna pretium in tincidunt. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu.",
    },
    {
      image: "../assets/images/user-feedback-img1.png",
      data: "May 8, 2020",
      title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim arcu. Elementum felis magna pretium in tincidunt. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim arcu. Elementum felis magna pretium in tincidunt. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu.",
    },
    {
      image: "../assets/images/user-feedback-img1.png",
      data: "May 8, 2020",
      title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim arcu. Elementum felis magna pretium in tincidunt. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim arcu. Elementum felis magna pretium in tincidunt. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu.",
    },
    {
      image: "../assets/images/user-feedback-img1.png",
      data: "May 8, 2020",
      title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim arcu. Elementum felis magna pretium in tincidunt. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim arcu. Elementum felis magna pretium in tincidunt. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu.",
    },
    {
      image: "../assets/images/user-feedback-img1.png",
      data: "May 8, 2020",
      title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim arcu. Elementum felis magna pretium in tincidunt. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim arcu. Elementum felis magna pretium in tincidunt. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu.",
    },
    {
      image: "../assets/images/user-feedback-img1.png",
      data: "May 8, 2020",
      title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim arcu. Elementum felis magna pretium in tincidunt. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim arcu. Elementum felis magna pretium in tincidunt. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu.",
    },
    {
      image: "../assets/images/user-feedback-img1.png",
      data: "May 8, 2020",
      title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim arcu. Elementum felis magna pretium in tincidunt. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim arcu. Elementum felis magna pretium in tincidunt. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu.",
    },
  ];
  return (
    <>
      <Container>
        <div className="row productDetailsCon">
          <div className="col-lg-6 ">
            <div className=" ">
              <img
                className="img-obj-wid"
                width={430}
                src={require("../assets/images/Rectangle 21988 (41).png")}
                alt="Google"
              />
            </div>
            <div className="productDetalsImgDiv">
              <div>
                <img
                  className="img-obj-wid img-obj-wid-cat"
                  width={130}
                  src={require("../assets/images/Rectangle 21988 (41).png")}
                  alt="Google"
                />
              </div>
              <div>
                <img
                  className="img-obj-wid img-obj-wid-cat"
                  width={130}
                  src={require("../assets/images/Rectangle 21988 (41).png")}
                  alt="Google"
                />
              </div>
              <div>
                <img
                  className="img-obj-wid img-obj-wid-cat"
                  width={130}
                  src={require("../assets/images/Rectangle 21988 (41).png")}
                  alt="Google"
                />
              </div>
              <div>
                <img
                  className="img-obj-wid img-obj-wid-cat"
                  width={130}
                  src={require("../assets/images/Rectangle 21988 (41).png")}
                  alt="Google"
                />
              </div>
            </div>
          </div>
          <div className="row col-lg-6 productDetailstextDiv">
            <div className="productDetailstextDivHEad">
              <div>
                <h2>Starter Kit</h2>
              </div>
              <div>
                <p>Item # 107890</p>
              </div>
            </div>
            <div className="productDetailstextDivHEadH4">
              <h4>Category Details</h4>
            </div>
            <div>
              <p>
                Formulated to combine the finest ingredients of 8 all green, and
                rich extracts of Aloe Vera, Barley, Avocado, Guava, Green Tea,
                Moringa, Calamansi, and Green Apple. This is an ideal cleanser
                for your face and body as it absorbs dirt, oil, and toxins from
                the skin. The antioxidants and vitamin levels in this soap helps
                improve skin’s firmness and keeps the skin moisturized. It
                soothes sunburns, and helps in the healing and disinfection of
                wounds, infections, acne, yeast, eczema, and other skin
                irritations.
              </p>
            </div>
            <div className="productDetailstextDivHeadQuan">
              <div>
                <h6>Quantity</h6>
              </div>
              <div className="col-md-4">
                <div className="price">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <button className="btn" type="button" onClick={decNum}>
                        -
                      </button>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      value={num}
                      onChange={handleChange}
                    />
                    <div className="input-group-prepend">
                      <button className="btn" type="button" onClick={incNum}>
                        +
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="productDetailstextDivHEadButton">
              <Button variant="Red">Add to Cart</Button>
            </div>
          </div>
        </div>
        <div className="row productDetailAboutCon">
          <div className="col-lg-8">
            <div>
              <h2 className="productDetailAboutConH2">ABOUT THIS PRODUCT</h2>
            </div>
            <div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
          </div>
          <div className="col-lg-8">
            <img
              className="img-obj-wid" 
              width={430}
              src={require("../assets/images/Rectangle 21974.png")}
              alt="Google"
            />
          </div>
        </div>
        <div className="row productDetailFeedbackCon">
          <div className="col-lg-8">
            <div>
              <h2>FEEDBACK ABOUT THIS PRODUCT</h2>
            </div>
          </div>
        </div>
      </Container>
      <div className="productDetailSwiper">
        <Swiper
          slidesPerView={2}
          spaceBetween={30}
          loop={true}
          centeredSlides={true}
          // navigation
          pagination={{
            clickable: true,
            renderBullet: customPagination.renderBullet,
            activeColor: '#c6282d',
          }}
          modules={[Pagination]}
          className="mySwipe "
        >
          <SwiperSlide
            className="userFeedbackSlider "
            style={{ width: "600px" }}
          >
            <div className="userFeedbackSliderDiv">
              <div className="userFeedbackSliderImg">
                <img
                  className=" img-obj-wid"
                  width={210}
                  src={require("../assets/images/user-feedback-img1.png")}
                  alt="Google"
                />
              </div>
              <div className="userFeedbackSliderText">
                <div>
                  <p>May 8, 2020</p>
                </div>
                <div>
                  <h4>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </h4>
                </div>
                <div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Cursus nibh mauris, nec turpis orci lectus maecenas.
                    Suspendisse sed magna eget nibh in turpis. Consequat duis
                    diam lacus arcu. Faucibus venenatis felis id augue sit
                    cursus pellentesque enim arcu. Elementum felis magna pretium
                    in tincidunt. Suspendisse sed magna eget nibh in turpis.
                    Consequat duis diam lacus arcu.Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit. Cursus nibh mauris, nec turpis
                    orci lectus maecenas. Suspendisse sed magna eget nibh in
                    turpis. Consequat duis diam lacus arcu. Faucibus venenatis
                    felis id augue sit cursus pellentesque enim arcu. Elementum
                    felis magna pretium in tincidunt. Suspendisse sed magna eget
                    nibh in turpis. Consequat duis diam lacus arcu.
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide
            className="userFeedbackSlider"
            style={{ width: "600px" }}
          >
            <div className="userFeedbackSliderDiv">
              <div className="userFeedbackSliderImg">
                <img
                  className=" img-obj-wid"
                  width={210}
                  src={require("../assets/images/user-feedback-img1.png")}
                  alt="Google"
                />
              </div>
              <div className="userFeedbackSliderText">
                <div>
                  <p>May 8, 2020</p>
                </div>
                <div>
                  <h4>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </h4>
                </div>
                <div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Cursus nibh mauris, nec turpis orci lectus maecenas.
                    Suspendisse sed magna eget nibh in turpis. Consequat duis
                    diam lacus arcu. Faucibus venenatis felis id augue sit
                    cursus pellentesque enim arcu. Elementum felis magna pretium
                    in tincidunt. Suspendisse sed magna eget nibh in turpis.
                    Consequat duis diam lacus arcu.Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit. Cursus nibh mauris, nec turpis
                    orci lectus maecenas. Suspendisse sed magna eget nibh in
                    turpis. Consequat duis diam lacus arcu. Faucibus venenatis
                    felis id augue sit cursus pellentesque enim arcu. Elementum
                    felis magna pretium in tincidunt. Suspendisse sed magna eget
                    nibh in turpis. Consequat duis diam lacus arcu.
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide
            className="userFeedbackSlider  "
            style={{ width: "600px" }}
          >
            <div className="userFeedbackSliderDiv">
              <div className="userFeedbackSliderImg">
                <img
                  className=" img-obj-wid"
                  width={210}
                  src={require("../assets/images/user-feedback-img1.png")}
                  alt="Google"
                />
              </div>
              <div className="userFeedbackSliderText">
                <div>
                  <p>May 8, 2020</p>
                </div>
                <div>
                  <h4>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </h4>
                </div>
                <div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Cursus nibh mauris, nec turpis orci lectus maecenas.
                    Suspendisse sed magna eget nibh in turpis. Consequat duis
                    diam lacus arcu. Faucibus venenatis felis id augue sit
                    cursus pellentesque enim arcu. Elementum felis magna pretium
                    in tincidunt. Suspendisse sed magna eget nibh in turpis.
                    Consequat duis diam lacus arcu.Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit. Cursus nibh mauris, nec turpis
                    orci lectus maecenas. Suspendisse sed magna eget nibh in
                    turpis. Consequat duis diam lacus arcu. Faucibus venenatis
                    felis id augue sit cursus pellentesque enim arcu. Elementum
                    felis magna pretium in tincidunt. Suspendisse sed magna eget
                    nibh in turpis. Consequat duis diam lacus arcu.
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide
            className="userFeedbackSlider"
            style={{ width: "600px" }}
          >
            <div className="userFeedbackSliderDiv">
              <div className="userFeedbackSliderImg">
                <img
                  className=" img-obj-wid"
                  width={210}
                  src={require("../assets/images/user-feedback-img1.png")}
                  alt="Google"
                />
              </div>
              <div className="userFeedbackSliderText">
                <div>
                  <p>May 8, 2020</p>
                </div>
                <div>
                  <h4>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </h4>
                </div>
                <div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Cursus nibh mauris, nec turpis orci lectus maecenas.
                    Suspendisse sed magna eget nibh in turpis. Consequat duis
                    diam lacus arcu. Faucibus venenatis felis id augue sit
                    cursus pellentesque enim arcu. Elementum felis magna pretium
                    in tincidunt. Suspendisse sed magna eget nibh in turpis.
                    Consequat duis diam lacus arcu.Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit. Cursus nibh mauris, nec turpis
                    orci lectus maecenas. Suspendisse sed magna eget nibh in
                    turpis. Consequat duis diam lacus arcu. Faucibus venenatis
                    felis id augue sit cursus pellentesque enim arcu. Elementum
                    felis magna pretium in tincidunt. Suspendisse sed magna eget
                    nibh in turpis. Consequat duis diam lacus arcu.
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide
            className="userFeedbackSlider "
            style={{ width: "600px" }}
          >
            <div className="userFeedbackSliderDiv">
              <div className="userFeedbackSliderImg">
                <img
                  className=" img-obj-wid"
                  width={210}
                  src={require("../assets/images/user-feedback-img1.png")}
                  alt="Google"
                />
              </div>
              <div className="userFeedbackSliderText">
                <div>
                  <p>May 8, 2020</p>
                </div>
                <div>
                  <h4>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </h4>
                </div>
                <div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Cursus nibh mauris, nec turpis orci lectus maecenas.
                    Suspendisse sed magna eget nibh in turpis. Consequat duis
                    diam lacus arcu. Faucibus venenatis felis id augue sit
                    cursus pellentesque enim arcu. Elementum felis magna pretium
                    in tincidunt. Suspendisse sed magna eget nibh in turpis.
                    Consequat duis diam lacus arcu.Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit. Cursus nibh mauris, nec turpis
                    orci lectus maecenas. Suspendisse sed magna eget nibh in
                    turpis. Consequat duis diam lacus arcu. Faucibus venenatis
                    felis id augue sit cursus pellentesque enim arcu. Elementum
                    felis magna pretium in tincidunt. Suspendisse sed magna eget
                    nibh in turpis. Consequat duis diam lacus arcu.
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide
            className="userFeedbackSlider "
            style={{ width: "600px" }}
          >
            <div className="userFeedbackSliderDiv">
              <div className="userFeedbackSliderImg">
                <img
                  className=" img-obj-wid"
                  width={210}
                  src={require("../assets/images/user-feedback-img1.png")}
                  alt="Google"
                />
              </div>
              <div className="userFeedbackSliderText">
                <div>
                  <p>May 8, 2020</p>
                </div>
                <div>
                  <h4>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </h4>
                </div>
                <div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Cursus nibh mauris, nec turpis orci lectus maecenas.
                    Suspendisse sed magna eget nibh in turpis. Consequat duis
                    diam lacus arcu. Faucibus venenatis felis id augue sit
                    cursus pellentesque enim arcu. Elementum felis magna pretium
                    in tincidunt. Suspendisse sed magna eget nibh in turpis.
                    Consequat duis diam lacus arcu.Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit. Cursus nibh mauris, nec turpis
                    orci lectus maecenas. Suspendisse sed magna eget nibh in
                    turpis. Consequat duis diam lacus arcu. Faucibus venenatis
                    felis id augue sit cursus pellentesque enim arcu. Elementum
                    felis magna pretium in tincidunt. Suspendisse sed magna eget
                    nibh in turpis. Consequat duis diam lacus arcu.
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide
            className="userFeedbackSlider "
            style={{ width: "600px" }}
          >
            <div className="userFeedbackSliderDiv">
              <div className="userFeedbackSliderImg">
                <img
                  className=" img-obj-wid"
                  width={210}
                  src={require("../assets/images/user-feedback-img1.png")}
                  alt="Google"
                />
              </div>
              <div className="userFeedbackSliderText">
                <div>
                  <p>May 8, 2020</p>
                </div>
                <div>
                  <h4>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </h4>
                </div>
                <div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Cursus nibh mauris, nec turpis orci lectus maecenas.
                    Suspendisse sed magna eget nibh in turpis. Consequat duis
                    diam lacus arcu. Faucibus venenatis felis id augue sit
                    cursus pellentesque enim arcu. Elementum felis magna pretium
                    in tincidunt. Suspendisse sed magna eget nibh in turpis.
                    Consequat duis diam lacus arcu.Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit. Cursus nibh mauris, nec turpis
                    orci lectus maecenas. Suspendisse sed magna eget nibh in
                    turpis. Consequat duis diam lacus arcu. Faucibus venenatis
                    felis id augue sit cursus pellentesque enim arcu. Elementum
                    felis magna pretium in tincidunt. Suspendisse sed magna eget
                    nibh in turpis. Consequat duis diam lacus arcu.
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
};

export default ProductDetailsPage;
